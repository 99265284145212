
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import SelectArrow from '@/assets/svg/select-arrow.svg?inline'
import AnaliticMenuSVG from '@/assets/svg/analitic-menu.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Pagination from '@/components/molecules/Pagination.vue'
import AnalyticsNavMenu from '@/components/molecules/AnalyticsNavMenu.vue'
import Select from '@/components/atoms/CustomSelect.vue'
import Calendar from '@/components/molecules/Calendar.vue'
import vClickOutside from 'click-outside-vue3'

export default defineComponent({
  name: 'Instructors',
  data () {
    return {
      page: 1,
      instructorsData: [
        {
          countProgres: 10
        },
        {
          countProgres: 60
        }
      ],
      instructorsMeta: null,
      errorInstructors: null,
      searchData: '',
      sortData: '',
      isModalDelete: false,
      idInstructor: null,
      dataAllInstructors: [],
      dataSkills: [],
      selectedInstructor: false,
      isCalendarVisible: false,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      dataLevels: [],
      filters: '',
      filtersDate: '',
      filtersLevel: '',
      filtersAnaliticsLevel: '',
      filtersScills: '',
      dataAllInstructorStudents: {},
      instructorNameSelected: '',
      instructorId: '',
      activeItemId: null,
      selectedNewDate: null,
      isMenuActive: false
    }
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Pagination,
    Select,
    Calendar,
    SelectArrow,
    AnaliticMenuSVG
  },
  watch: {
    searchData () {
      this.getInstructors()
      this.page = 1
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted () {
    this.getAnalytics()
    this.getInstructors()
    this.getLevels()
    this.getSkills(1)
  },
  methods: {
    getAnalytics (params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = ''
      }
      params =
        searchParam + '&n=20&sort=' + sort + this.filters + this.filtersDate + this.filtersAnaliticsLevel
      this.$store.dispatch('analytics/averageTimeSpentOnLevel', params).then(
        (res) => {
          this.analyticsData = res.data
          this.analyticsMeta = res.meta
        },
        (error) => {
          this.erroranalytics = error.response.data.errors
        }
      )
    },
    getInstructors () {
      const params = '?n=100'
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.dataAllInstructors = res.data
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getInstructorStudents (id, name, sort) {
      let filterScills = ''
      let filterLevel = ''
      if (this.filtersScills === '&filter[skill_id]=undefined') {
        filterScills = ''
      } else filterScills = this.filtersScills
      if (this.filtersLevel === '&filter[level_id]=undefined') {
        filterLevel = ''
      } else filterLevel = this.filtersLevel
      this.activeItemId = id
      this.instructorId = id
      if (sort === undefined) {
        sort = ''
      }
      const params = {
        id: id,
        filters: `?${filterLevel}&${filterScills}&`,
        sort: `${sort}`
      }
      this.$store.dispatch('instructors/getInstructorStudents', params).then(
        (res) => {
          this.instructorNameSelected = name
          this.dataAllInstructorStudents = res.data
          this.selectedInstructor = true
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getLevels () {
      this.$store.dispatch('general/getLevels').then(
        (res) => {
          this.dataLevels = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    getSkills (id) {
      this.$store.dispatch('general/getSkillsByLevels', id).then(
        (res) => {
          this.dataSkills = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getInstructors()
    },
    handleOptionSelected (selectedOption) {
      this.filters = `&filter[instructor_id]=${selectedOption.id}`
      this.getAnalytics()
    },
    handleOptionSelectedLevels (selectedOption) {
      this.filtersAnaliticsLevel = `&filter[level_id]=${selectedOption.id}`
      this.getAnalytics()
    },
    handleOptionLevelSelected (selectedOption) {
      this.getSkills(selectedOption.id)
      this.filtersLevel = `&filter[level_id]=${selectedOption.id}`
      if (selectedOption.id !== undefined) {
        this.getInstructorStudents(
          this.instructorId,
          this.instructorNameSelected
        )
      }
    },
    handleOptionSkillsSelected (selectedOption) {
      this.filtersScills = `&filter[skill_id]=${selectedOption.id}`
      if (selectedOption.id !== undefined) {
        this.getInstructorStudents(
          this.instructorId,
          this.instructorNameSelected
        )
      }
    },
    toggleCalendar () {
      this.isCalendarVisible = !this.isCalendarVisible
    },
    closeCalendar () {
      this.isCalendarVisible = false
    },
    selectDateFill (date, month, year) {
      const selectedDate = new Date(year, month, date.value)
      const currentDate = new Date()
      if (selectedDate > currentDate) {
        return
      }
      if (date.value !== '') {
        const selectedDate = new Date(year, month, date.value)
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
          this.selectedEndDate = null
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`
        this.getAnalytics()
      }
    },
    formatDate (dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      const day = date.getDate()
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)
      const year = date.getFullYear()
      return `${day} ${month} ${year}`
    },
    resetFilters () {
      window.location.reload()
    },
    resetStidentsFilters () {
      this.filtersScills = ''
      this.filtersLevel = ''
      this.getInstructorStudents(this.instructorId, this.instructorNameSelected)
      this.clearSelection()
    },
    clearSelection () {
      if (this.$refs.customSelectRefLevel) {
        this.$refs.customSelectRefLevel.clearSelected()
      }
      if (this.$refs.customSelectRefSkills) {
        this.$refs.customSelectRefSkills.clearSelected()
      }
    },
    toggleMenu () {
      this.isMenuActive = !this.isMenuActive
    },
    closeMenu () {
      this.isMenuActive = false
    }
  }
})
